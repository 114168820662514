* {
    box-sizing: border-box;
}
*:before, *:after {
    box-sizing: border-box;
}
article, aside, details, figcaption, figure, footer, hgroup, menu, nav, section {
    display: block;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
 
.clearfix:after {
    clear: both;
}
 
/* IE6/7 support */
.clearfix {
    *zoom: 1;
}
html {
    -webkit-text-size-adjust: 100%;
    -webkit-transform: translate3d(0,0,0);
}
body {
    min-width: 320px;
    font-size: 14px;
    font-family: Arial;
    line-height: 1.42857;
    background-color: #aaa;
}
img {
    max-width: 100%;
    height: auto;
}
a, a:hover {
    color: #00f;
}
.main-wrapper {
    background-color: #fff;
    max-width: 1366px;
    margin: 0 auto;
}
header a, header a:hover {
    color:#fff;
}
header .top-line {
    height: 24px;
    position: relative;
    font-size: 13px;
    background-color: #111;
    color: #fff;
}
header .top-line ul.menu-md {
    margin: 0;
    padding: 3px 10px;
    list-style: none;
    dispaly: block;
    float: right;
    height: 100%;
}
header .top-line ul li {
    display: block;
    float: left;
    padding: 0 15px;
}
header .top-line ul li a {
    display: block;
    height: 100%;
    color: #fff;
    text-decoration: none;
}
header .top-line ul.menu-xs {
    margin: 0;
    padding: 0 1px;
    list-style: none;
    float: right;
    height: 100%;
    display: none;
}
header .top-line ul.menu-xs li.ico,
header .top-line ul.menu-md li.ico{
    height: 100%;
    width: 38px;
    padding: 0 10px;
}
header .top-line ul.menu-xs li.ico-home {
    background: #000 url(/design/sprite.png) no-repeat scroll 10px 4px;
}
header .top-line ul.menu-xs li.ico-menu,
header .top-line ul.menu-md li.ico-menu{
    background: #000 url(/design/sprite.png) no-repeat scroll 10px -14px;
}
header .top-line ul.menu-xs li.ico a:before {
    display: block;
    content: " ";
}
header .middle-line {
    height: 80px;
    background-color: #fff;
    color: #000;
}
header .middle-line .logo {
    width: 60px;
    height: 60px;
    margin: 10px 0 0 23px;
    float: left;
}
header .middle-line .logo a {
    display: block;
    height: 100%;
}
header .middle-line .logo img {
    width:100%;
    max-width: 60px;
}
header .middle-line .logo-named {
    width: 240px;
    height: 70px;
    padding-top: 8px;
    margin: 0 auto; 
}
header .middle-line .logo-named a {
    display: block;
    height: 100%;
}
header .middle-line .logo-named img {
    width:100%;
    max-width: 298px;
}
header .middle-line .slider-block {
    display: none;
    height: 165px;
    margin-right: 40px;
}
header .middle-line .slider-block .slider-th-block {
    height: 100%;
    margin-right: 500px;
}
header .middle-line .slider-th-block ul {
    margin: 0;
    padding: 0;
    list-style: none; 
    width:100%; 
    padding-top: 20px; 
    float: right; 
    margin-right: -15px;
    width:120%;
    max-width:220px;
}
header .middle-line .slider-th-block ul li.item {
    height: 42px;
    margin-bottom: 16px;
    background: url('/design/miniBanner2.png') no-repeat top right;
    -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 10px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 10px rgba(255, 255, 255, 1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 10px rgba(255, 255, 255, 1);
}
header .middle-line .slider-th-block ul li.item a {
    display: block;
    width: 100%;
    height:inherit;
    text-decoration:none;
}
header .middle-line .slider-block .slider {
    display: block;
    width: 500px;
    height: 190px;
    float: right;
    background: #000;
    -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 10px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 10px rgba(255, 255, 255, 1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 10px rgba(255, 255, 255, 1);
}
header .middle-line .slider img {
    width: 100%;
    max-width: 500px;
    max-height: 190px;
}
header .middle-line .slider-block .slider-lt-block {
    display: none;
    height: 100%;
    color: #fff;
    margin-left: 620px;
    position: relative;
    max-width: 490px;
}
header .middle-line .slider-block .slider-lt-block .phones-block {
    font-size: 16px;
}
header .middle-line .slider-block .slider-lt-block .phones-block .item {
    width: 190px;
    float: left;
    padding-bottom: 10px;
    text-align: center;
}
header .middle-line .slider-block .slider-lt-block .phones-block .item.mail {
    font-size: 13px;
    padding-bottom: 20px;
}
header .bottom-line {
    height: 190px;
    background-color: #111;
    color: #fff;
    display: none;
}
header .bottom-line .phones-block {
    font-size: 16px;
    padding: 5px 10px 0 0;
}
header .bottom-line .phones-block .item {
    width: 125px;
    float: right;
    text-align: center;
}
header .bottom-line .phones-block .item a {
    color: #fff;
    text-decoration: none;
}
.slider-lt-block .text-block {
    display: none;
    padding: 10px 10px 10px 20px;
    font-size: 12px;
    text-align: left;
    max-width: 440px;
}
.address-block-sm {
    padding: 10px 5px;
    white-space: nowrap;
    padding: 20px 15px 15px;
    font-size: 12px;
    line-height: 1.5em;
    text-align: center;
}
.breadcrambs {
    padding-bottom: 10px;
}
.breadcrambs > * {
     white-space: nowrap;
}
.product-block {
    padding: 10px;
}
.product-block .product-image {
    float: left;
    padding-bottom: 20px;
}
.product-info {
    width: 300px;
    float: left;
}
.product-info .product-title {
    background: #000;
    color: #fff;
    font-size: 18px;
    height: 37px;
    margin-bottom: 20px;
    opacity: 0.7;
    padding: 5px 0;
    text-align: center;
}
.product-info .product-description {
    
}
.product-info .product-description table {
    width : 100%;
    background: #f0f0f0;
    border-collapse: collapse;
    font-size: 13px;
    margin-bottom: 30px;
}
.product-info .product-description table tbody tr:nth-of-type(odd) {
    background: #fff;
}
.product-info .product-description table tbody th {
    padding: 3px;
    text-align: left;
}
.product-info .product-description table td {
    padding: 3px;
    text-align: center;
}
.product-info .product-description table tbody tr:nth-of-type(1) td:nth-of-type(1) {
    text-align: left;
    padding-bottom: 20px;
}
.product-info .product-description table tbody tr td:nth-of-type(2) {
    text-align: right;
}
.product-info .product-price {
    margin: 0 !important;
}
.more-products-block {
    height: 160px;
    padding-top: 30px;
}
.more-products-block .small-images {
    width: 120px;
    height: 120px;
    border: 1px solid #777;
    float: left;
    margin: 0 0 14px 14px;
}
/***********************************   Content ****************************/
.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;
}
.container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    background-color: #CCCCCC;
}
.content-holder {
    clear: both;
    float: none;
    padding-top: 12px;
    max-width: 1366px;
}
.main-content {
    padding-top:25px;
}
.main-content .item-wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    width: 100%;
}
.main-content .item-wrapper .item {
    display: block;
    float: left;
}
.banner-block {
    display: none;
}
.fixed-banner-block {
    display: none;
    width: 93px;
    position: fixed;
    left: 0;
    top: 128px;
}
.fixed-banner-block .banner-tab {
    width:100%;
    height:43px;
    margin-bottom: 15px;
    background: url('/design/miniBanner.png') no-repeat top right;
}
.fixed-banner-block .banner-tab a{
    display: block;
    width: 100%;
    height: inherit;
    text-decoration: none;
}
.left-sidebar {
    display: none;
    width: 220px;
    height: 400px;
    margin: 22px 0 0 8px;
}
.main-menu {
    font-family: Arial;
    font-size: 14px;
}
.main-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background: #3d0000;
}
.main-menu ul li {
    height: 35px;
    margin-bottom:1px;
    background: #5d0000;
}
.main-menu ul li a{
    color:#fff;
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px 5px 5px 10px;
}
.main-menu-md-container {
    display: none;
}
.main-nav ul.item-wrapper {
    list-style: none;
    margin: 15px 0 0 0;
    padding: 0;
    width: 100%;
}
.main-nav ul.item-wrapper:after {
    display: block;
    content: " ";
    clear: both;
    float: none;
}
.main-nav ul.item-wrapper li.item {
    display: block;
    width: 100%;
    min-height: 25px;
    padding: 7px;
    float: left;
}
.main-nav ul.item-wrapper li.item a {
    display: block;
    height: 100%;
    min-height: 25px;
    width: 100%;
    max-width: 305px;
    margin: 0 auto;
    position: relative;
    border: 1px solid #000;
}
.main-nav ul.item-wrapper li.item a img {
    display: block;
    border: 0;
    width: 100%;
}
.main-nav ul.item-wrapper li.item a .title {
    display: block;
    width: 100%;
    height: 25px;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    bottom: 0;
    background-color: #000;
    color: #fff;
    opacity: 0.7;
}
.contacts-block {
    height: 80px;
    background-color: #aaaaaa;
    opacity: 0.2;
}
footer .top-line {
    margin: 5px 0;
    background-color: #aaa;
    opacity: 0.2;
}
footer .bottom-line {
    background-color: #000;
    color: #fff;
    font-size: 12px;
    font-family: Verdana;
    text-align: center;
    padding: 0 7px;
}
footer .bottom-line > div {
    display: inline-block;
}
footer .bottom-line:after {
    display: block;
    clear: both;
    content:"";
}
footer .bottom-line a {
    color: #fff;
}
.catalog-products .item {
    padding: 10px 0;
    width: 25%;
}
.catalog-products .product-block {
    padding: 7px 9px;
    margin: 0 3px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f3f3f3+0,aaaaaa+100 */
    background: #f3f3f3; /* Old browsers */
    background: -moz-linear-gradient(top, #f3f3f3 0%, #aaaaaa 100%); /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f3f3f3), color-stop(100%,#aaaaaa)); /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(top, #f3f3f3 0%,#aaaaaa 100%); /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(top, #f3f3f3 0%,#aaaaaa 100%); /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(top, #f3f3f3 0%,#aaaaaa 100%); /* IE10 preview */
    background: linear-gradient(to bottom, #f3f3f3 0%,#aaaaaa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f3f3', endColorstr='#aaaaaa',GradientType=0 ); /* IE6-9 */
}
.catalog-products .image-block {
    width: 215px;
    height:215px;
    border:1px solid #777777;
    margin: 0 auto;
}
.catalog-products .name-block {
    width: 100%;
    height: 65px;
    max-width: 215px;
    margin: 0 auto;
}
.catalog-products .name-block a, 
.catalog-products .name-block a:hover {
    color: #000;
    text-decoration: none;
}
.catalog-products .name-block .name {
    width: 100%;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
}
.catalog-products .name-block .article {
    width: 100%;
    text-align: center;
    font-family: Arial;
    font-size: 13px;
    text-decoration: italic;
    color: #777777;
}
.catalog-products .price-block {
    padding-top: 10px;
    width: 100%;
    max-width: 215px;
    margin: 0 auto;
}
.catalog-products .price-block .price,
.product-info .product-price {
    background: #5d0000;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    max-width: 150px;
    padding: 10px;
    margin: 0 auto;
    text-align: center;
    cursor: default;
}
.elastic-image-wrapper {
    width: 100%;
    max-width: 215px;
    margin: 0 auto;
}
.elastic-image-container {
    position: relative;
    padding-bottom: 100%;
    padding-top: 10px;
    height: 0;
    overflow: hidden;
}
.elastic-image-container > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.elastic-image {
    border: 0;
    max-width: 100%;
    height: auto;
}
.standartTitleBlock {
    font-size:18px;
    background:#000000;
    color:#FFFFFF;
    filter:alpha(opacity=70);
    opacity:0.7;
    height: 46px;
    padding: 10px 15px;
    margin-bottom:20px;
}
.columnContainer {
    width:100%;
}
.columnContainer:after {
    display:block;
    content:" ";
    clear:both;
    float:none;
}
.columnContainer .column {
    float:left;
}
#contactsColumnContainer1 > :nth-child(1) {
    width:60%;
}
#contactsColumnContainer1 > :nth-child(2) {
    width:40%;
    padding-left:25px;
}
.titleBlockColumnContainer:after {
    display:block;
    content: " ";
    clear:both;
    float:none;
}
.far {
  position:relative;
}
.far:after {
  padding-top:100%;
  display:block;
  content:' ';
}
.far .far-content {
  position:absolute;
  width:100%;
  height:100%;
  overflow:hidden;
}
#contactsMapContainer {
  background:#ccc;
  max-width:740px;
}
#contactsMapContainer:after {
  padding-top:56%;
}

#contactsColumnContainer1 {
    padding-bottom:30px;
}
#addressBlock {
    padding-left:15px;
}
#addressBlock.columnContainer > .column:nth-child(1) h3:nth-of-type(1) {
    margin-top:0;
}
#feedbackFormContainer {
    width:60%;
}
#feedbackForm {
    max-width:740;
    width:100%;
}
#feedbackForm input[type='text'], #feedbackForm textarea {
    width:100%;
    padding:2px;
    border:1px solid #d9d9d9;
}
#feedbackForm textarea {
    height:100%;
}
#feedbackForm > div {height:30px;}
#feedbackForm > div:nth-of-type(3) {height:160px;}
#feedbackForm > div:nth-of-type(4) {height:90px;}




@media screen and (min-width: 801px) {         /* ==================  801 - 1920     ======================= */
header .middle-line .slider-block {
    display: block;
    background-color: #000;
    margin-right: 0;
    height: 190px;
}
header .middle-line .slider-block .slider {
    float: left;
    margin-left: 120px;
}
header .middle-line .slider-block .slider-th-block {
    width: 120px;
    float: left;
    margin-left: -620px;
    background-color: #fff;
}
header .middle-line .slider-block .slider-lt-block {
    display: block;
}
}
@media screen and (min-width: 960px) {         /* ==================  960 - 1920     ======================= */
header .middle-line .slider-block .slider-lt-block .phones-block {
    position: absolute;
    width: 100%;
    bottom: 0;
}
header .middle-line .slider-block .slider-lt-block .phones-block .item {
    width: 50%;
}
header .middle-line .slider-block .slider-lt-block .phones-block .item:nth-of-type(1) {
    display: none;
}
.slider-lt-block .text-block {
    display: block;
}
.address-block-sm {
    padding-top: 5px;
}
}
@media screen and (min-width: 1025px) {          /* ==================  1025 - 1920     ======================= */
header .middle-line .slider-block .slider {
    margin-left: 230px;
}
header .middle-line .slider-block .slider-th-block {
    width: 230px;
    margin-left: -730px;
}
header .middle-line .slider-block .slider-lt-block {
    margin-left: 730px;
}
header .top-line ul.menu-md li.ico-menu {
    display: none;
}
.main-nav, .main-content {
    width: 76%;
    max-width: 995px;
    float: left;
    margin-left: 8px;
}
.left-sidebar {
    display: block;
    float: left;
}
.main-menu-md-container {
    display: none;
}
}
@media screen and (min-width: 1170px) {         /* ==================  1170 - 1920     ======================= */
header .middle-line .slider-block .slider-lt-block {
    margin-left: 61.5%;
}
.slider-lt-block .text-block {
    padding-top: 15px;
}
header .middle-line .slider-block .slider-lt-block .phones-block {
    width: 60%;
    bottom: 0;
    right: 45px;
}
}
@media screen and (min-width: 1200px) {         /* ==================  1200 - 1920     ======================= */
.main-nav, .main-content {
    margin-left: 3%;
}
}
@media screen and (min-width: 1250px) {         /* ==================  1250 - 1920     ======================= */
header .middle-line .slider-block .slider-lt-block {
    margin-left: 768px;
}
}
@media screen and (min-width: 696px) {         /* ==================  696     ======================= */
.main-nav ul.item-wrapper li.item {
    width: 33.33333%
}
}
@media screen and (min-width: 640px) and (max-width: 959px) {         /* ==================  640 - 959     ======================= */
header .middle-line .slider-block .slider-lt-block .phones-block .item {
    width: 100%;
}
header .bottom-line .phones-block {
    padding-top: 33px;
    padding-right: 35px;
    font-size: 17px;
}
header .bottom-line .phones-block .item {
    width: 140px;
}
}
@media screen and (min-width: 640px) and (max-width: 800px) {      /* ==================  640 - 800     ======================= */
header .middle-line {
    height: 235px;
}
header .middle-line .logo-named {
    width: 220px;
    height: 65px;
    padding-top: 8px;
}
header .middle-line .slider-block {
    display: block;
}
header .bottom-line {
    height: 70px;
    display: block;
}
header .bottom-line .phones-block {
    padding-top: 40px;
}
}
@media screen and (min-width: 696px) and (max-width: 768px) {      /* ==================  696 - 768     ======================= */
header .middle-line .slider-block {
    margin-right: 3%;
}
}
@media screen and (min-width: 640px) and (max-width: 695px) {      /* ==================  640 - 695     ======================= */
header .middle-line .slider-block {
    margin-right: 3.7%;
}
.main-nav ul.item-wrapper li.item {
    width: 50%
}
}
@media screen and (min-width: 480px) and (max-width: 639px) {      /* ==================  480 - 639     ======================= */
.fixed-banner-block {
    display: block;
    width: 19.4%;
}
.main-nav, .main-content {
    margin-left: 60px;
}
}
@media screen and (max-width: 1199px) {       /* ==================  0 - 1199     ======================= */
.catalog-products .item {
    width: 33.33333%;
}
}
@media screen and (max-width: 1024px) {       /* ==================  0 - 1024     ======================= */
}
@media screen and (max-width: 768px) {       /* ==================  0 - 768     ======================= */
#contactsColumnContainer1 {
    padding-bottom: 0;
}
#contactsColumnContainer1 > .column {
  padding: 0 0 25px;
  width: 100%;
}
#addressBlock.columnContainer > .column {
    width:50%;
}
#addressBlock.columnContainer > .column h3:nth-of-type(1) {
    margin-top:0;
}
#feedbackFormContainer {
    width:100%;
}
}
@media screen and (max-width: 639px) {       /* ==================  0 - 639     ======================= */
header .middle-line {
    height: 40px;
}
header .middle-line .logo {
    width: 34px;
    height: 34px;
    margin-top: 3px;
    margin-left: 8px;
}
header .middle-line .logo-named {
    width: 160px;
    padding-top: 1px;
}
header .bottom-line {
    display: block;
    height: 30px;
}
.main-content {
    margin-left: 20%;
}
.catalog-products .item {
    width: 50%;
}
}
@media screen and (max-width: 600px) {   /* ==================  0 - 600     ======================= */
#addressBlock.columnContainer > .column {
    width:100%;
}
#addressBlock.columnContainer > .column:nth-child(2) h3:nth-of-type(1) {
    margin-top:16px;
}
}
@media screen and (max-width: 479px) {   /* ==================  0 - 479     ======================= */
.container-fluid {
    padding-left: 10px;
    padding-right: 10px;
}
.banner-block {
    display: block;
    text-align: justify;
    line-height: 0;
    font-size: 1px; /* Opera */
    //text-justify: newspaper; /* IE6-7 */
    //text-align-last: justify; /* IE6-7 */
}
.banner-block:after {
    width: 100%;
    height: 0;
    content: '';
    display: inline-block;
    visibility: hidden;
    overflow: hidden;
}
.banner-block .banner-tab {
    width: 31%;
    height: 43px;
    display: inline-block;
    text-align: left;
    line-height: normal;
    font-size: medium;
    //display : inline; /* IE6-7 */
    //zoom : 1; /* IE6-7 */
    background: url('/design/miniBanner.png') no-repeat top right;
}
.banner-block .banner-tab a{
    display: block;
    width: 100%;
    height:inherit;
    text-decoration:none;
}
.main-nav ul.item-wrapper li.item {
    margin-left: auto;
    margin-right: auto;
}
.main-content {
    margin-left: 0;
}
}
@media screen and (max-width: 430px) {       /* ==================  0 - 430     =======================*/
.catalog-products .item {
    width: 100%;
}
}
@media screen and (max-width: 360px) {       /* ==================  0 - 360     =======================*/
.product-info {
    width: 100%;
}
.product-info .product-title {
    font-size: 16px;
    font-weight: bold;
}
}
@media screen and (max-width: 320px) {       /* ==================  0 - 320     =======================*/
header .top-line ul.menu-md {
    display: none;
}
header .top-line ul.menu-xs {
    position: absolute;
    right: 0;
    display: block;
}
footer .top-line {
    height: 40px;
}
footer .bottom-line > div {
    display: block;
}
}